import React, { useState, useRef } from 'react';
import { MarkerF, InfoWindowF } from '@react-google-maps/api';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';
import StyledMap, { getIcon } from './StyledMap';
/* React 18+ might have to use MarkerF instead of Marker to work */

const PartnerMap = inject('blockDataStore')(
  observer((props) => {
    const [selectedMarker, setSelectedMarker] = useState(null);
    const mapRef = useRef(false);
    const clickMap = (obj) => {
      if (obj) {
        setSelectedMarker(null);
      }
    };
    const onLoadMap = (map) => {
      mapRef.current = map;
      if (!window?.google?.maps || !props.locations) {
        return;
      }
      const google = window.google;
      const bounds = new google.maps.LatLngBounds(mapsLocation);
      const locations = props.locations;
      for (const location of locations) {
        if (location.latlng) {
          bounds.extend(location.latlng);
        }
      }
      map.fitBounds(bounds);
      const listener = google.maps.event.addListener(map, 'idle', (e) => {
        if (map.getZoom() > 14) {
          map.setZoom(14);
        }
        google.maps.event.removeListener(listener);
      });
    };
    const clickMarker = (slug) => {
      setSelectedMarker(slug);
    };
    const onCloseInfo = (e) => {
      setSelectedMarker(null);
    };
    const mapsLocation = {
      lat: props.partner?.address?.latlng[0],
      lng: props.partner?.address?.latlng[1],
    };
    const primaryColor = props.partner?.primary_color;
    const allowed = props.blockDataStore.allowGoogleMaps;
    if (!allowed) {
      return <BlockButton onClick={props.blockDataStore.onClickEnableOption} />;
    }

    /* pass isVisible from VisibilitySensor */
    return (
      <StyledMap isVisible={props.isVisible} center={mapsLocation} zoom={10} onClick={clickMap} onLoad={onLoadMap}>
        {Array.isArray(props.locations) &&
          props.locations.map((loc) => {
            if (!loc.latlng) {
              return null;
            }
            const addressQuery = encodeURIComponent(loc.title + ' ' + loc.street + ',' + loc.zip + ' ' + loc.city);

            return (
              <MarkerF
                key={loc.slug}
                position={loc.latlng}
                icon={getIcon(primaryColor, 0.8)}
                onClick={() => clickMarker(loc.slug)}
                title={loc.title}
              >
                {selectedMarker && selectedMarker === loc.slug && (
                  <InfoWindowF position={loc.latlng} onCloseClick={onCloseInfo}>
                    <div className="mink-google-map__info-window">
                      <p className="-title">
                        <strong>{loc.title}</strong>
                      </p>
                      <p>
                        {loc.street}
                        <br />
                        {loc.zip} {loc.city}
                      </p>
                      <p>
                        <a href={loc.urlc.path} rel="noopener noreferrer">
                          Zum Standort
                        </a>
                        <br />
                        <a
                          href={'https://www.google.com/maps/search/?api=1&query=' + addressQuery}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Zu Google Maps
                        </a>
                      </p>
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            );
          })}
      </StyledMap>
    );
  })
);

export default PartnerMap;
