import React from 'react';
import SubMenu from '../../components/nav/SubMenu/SubMenu';
import { observer, inject } from 'mobx-react';

const FooterNav = inject('navigationStore')(
  observer((props) => {
    const footerMenu = [
      {
        title: 'Impressum',
        url: '/impressum/',
        rel: 'nofollow',
      },
      {
        title: 'Datenschutzerklärung',
        url: '/datenschutzerklaerung/',
        rel: 'nofollow',
      },
      {
        title: 'Sanoa.net',
        url: 'https://sanoa.net/',
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    ];

    return (
      <nav className="mink-footer-nav">
        <div className="row">
          <div className="col-12">
            <div className="mink-footer-nav__menu">
              <SubMenu items={footerMenu} depthLevel={0} />
            </div>
          </div>
        </div>
      </nav>
    );
  })
);

export default FooterNav;
