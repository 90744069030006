import React from 'react';
import { inject, observer } from 'mobx-react';
import ACFEmbedVideo from '../ACFEmbedVideo/ACFEmbedVideo';

/**
 * returns youtube video with parms
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#Embed:-Video
 */

const embedWithParams = (iframeHtml) => {
  // use nocookie url, add parameters
  // rel: only related videos from the same channel
  // cc_load_policy: always show subtitles
  const parameters = 'rel=0&cc_load_policy=1';

  if ('string' === typeof iframeHtml && iframeHtml.length > 0) {
    // eslint-disable-next-line no-useless-escape
    const srcRegExp = /src="(?:[^"\/]*\/)*([^"]+)"/g;
    const src = iframeHtml.match(srcRegExp)?.[0]; // scr="..."
    if (src) {
      const separator = src.indexOf('?') === -1 ? '?' : '&';
      let srcNew;
      srcNew = src.replace('youtube.com/embed/', 'youtube-nocookie.com/embed/');
      srcNew = srcNew.substring(0, srcNew.length - 1) + separator + parameters + '"';
      return iframeHtml.replace(src, srcNew);
    }
  }
  return '';
};

const ACFEmbedYouTube = inject('blockDataStore')(
  observer((props) => {
    const data = {
      provider: 'youtube',
      className: ' -youtube',
      embedUrl: props.content.mink_fc_embed_video_url,
      allowVideo: props.blockDataStore.allowYoutube,
      contentUrl: props.content.mink_fc_embed_video_script_raw_url,
      title: props.content.mink_fc_embed_video_title,
      text: props.content.mink_fc_embed_video_text,
      link: props.content.mink_fc_embed_video_link,
      date: props.content.mink_fc_embed_video_upload_date,
      description: props.content.mink_fc_embed_video_script_description,
      thumbnail: props.content.mink_fc_embed_video_thumbnail,
      iframe: embedWithParams(props.content.mink_fc_embed_video_embed),
    };

    return <ACFEmbedVideo {...data} />;
  })
);

export default ACFEmbedYouTube;
