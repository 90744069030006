import React, { useState, useEffect, useRef } from 'react';
import SubMenuModerated from '../SubMenu/SubMenuModerated';
import { itemContainsUrl } from '../SubmenuItem/SubMenuItem';
import { renderText } from '../../../helpers/api';
import CollapseSimple from '../../elements/Collapse/CollapseSimple';
import useEventListener from '@use-it/event-listener';
import { observer, inject } from 'mobx-react';
import UniLink from '../../elements/UniLink/UniLink';

const SubMenuModeratedItem = inject('navigationStore')(
  observer((props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDefaultOpen, setIsDefaultOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const element = useRef(null);

    useEffect(() => {
      if (props.navigationStore.navIsActive) {
        // menu just opened, open path to current link
        setIsOpen(isDefaultOpen);
      }
    }, [props.navigationStore.navIsActive, isDefaultOpen]);

    const handleDocumentClick = (e) => {
      // close submenu on parent or sibling click
      const container = element.current;
      if (e.target !== props.rootElement?.current && e.target !== container && !container?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    // see https://usehooks.com/useEventListener/
    useEventListener('click', handleDocumentClick, document, { capture: true });

    const item = props.item;
    if (!item.classes) {
      item.classes = [];
    }
    const level = props.depthLevel;
    const currentClass = 'current-menu-item';
    // const isCollapse = item.children && item.children.length > 0;
    const isCollapse = item.classes.includes('toggler');
    const isCurrent = props.navigationStore.isCurrentUrl(item.url);

    useEffect(() => {
      if (item.children && props.navigationStore.pathname) {
        setIsDefaultOpen(itemContainsUrl(item, props.navigationStore.pathname));
      }
    }, [item, props.navigationStore.pathname]);

    return (
      <li
        ref={element}
        key={'menu_item_' + (item.menu_item_id ? item.menu_item_id : item.title)}
        className={'nav_item ' + (isCurrent ? currentClass : '') + ' ' + item.classes.join(' ')}
      >
        {isCollapse ? (
          <button
            className={'btn btn-link btn-reset ' + (isOpen ? 'open ' : '') + (isDefaultOpen ? 'current-path' : '')}
            onClick={toggle}
          >
            {renderText(item.title)}
          </button>
        ) : (
          <>
            {isCurrent ? (
              <span>{renderText(item.title)}</span>
            ) : (
              <UniLink
                rel={item.rel ? item.rel : undefined}
                onClick={props.handleLinkClick(item)}
                href={item.url}
                target={item.target ? item.target : undefined}
              >
                {renderText(item.title)}
              </UniLink>
            )}
          </>
        )}
        {item.children && (
          <>
            {isCollapse ? (
              <CollapseSimple className="sn-collapse" timeout={0} isOpen={isOpen}>
                <SubMenuModerated
                  items={item.children}
                  depthLevel={level + 1}
                  rootElement={props.rootElement}
                  handleLinkClick={props.handleLinkClick}
                />
              </CollapseSimple>
            ) : (
              <SubMenuModerated
                items={item.children}
                depthLevel={level + 1}
                rootElement={props.rootElement}
                handleLinkClick={props.handleLinkClick}
              />
            )}
          </>
        )}
      </li>
    );
  })
);

export default SubMenuModeratedItem;
