/**
  Helper functions for communication with REST API
 */
import React from 'react';
import Helmet from 'react-helmet';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import YoutubeIFrame from '../components/elements/embed/YoutubeIFrame';
import VimeoIFrame from '../components/elements/embed/VimeoIFrame';
import FormIFrame from '../components/elements/embed/FormIFrame';
import Form from '../components/posts/Form/Form';
import UniLink from '../components/elements/UniLink/UniLink';

const getMainDomain = () => {
  let domain = getHomeUrl();

  if (window.SN && window.SN.subdomain) {
    domain = getHomeUrl().replace(getSubdomain() + '.', '');
  }

  return domain;
};

/**
 * returns the home url of the website
 *
 * a static variable is used to store the url
 * @see https://chamnapchhorn.blogspot.com/2008/07/trick-to-use-static-variables-in.html
 */
const getHomeUrl = (() => {
  var url;
  if (url) {
    return () => url;
  }
  if (
    typeof window !== 'undefined' &&
    typeof window.location !== 'undefined' &&
    window.location.hostname !== 'localhost' &&
    !/192\.168\./.test(window.location.hostname)
  ) {
    url =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '/');
  } else if (typeof process.env.REACT_APP_REST_API_URL !== 'undefined') {
    url = process.env.REACT_APP_REST_API_URL;
  } else if (typeof process.env.REACT_APP_FALLBACK_REST_API_URL !== 'undefined') {
    url = process.env.REACT_APP_FALLBACK_REST_API_URL;
  } else {
    url = '/';
  }
  return () => url;
})();

const getSubdomain = () => {
  if (typeof window !== 'undefined' && typeof window.SN !== 'undefined' && window.SN.subdomain) {
    return window.SN.subdomain;
  }
  return '';
};

const getEndpointUrl = () => {
  return getHomeUrl() + 'wp-json/';
};

const getConfig = (config) => {
  if (typeof window !== 'undefined' && typeof window.SN !== 'undefined' && 'undefined' !== window.SN[config]) {
    return window.SN[config];
  } else {
    return null;
  }
};

const getApiconfig = () => {
  const config = {
    endpoint: getEndpointUrl(),
  };
  if (typeof window !== 'undefined' && typeof window.SN !== 'undefined' && window.SN.nonce) {
    config.nonce = window.SN.nonce;
  }

  return config;
};

const getSiteName = () => {
  let siteName = '';
  if (typeof window !== 'undefined' && typeof window.SN !== 'undefined' && window.SN.title) {
    siteName = window.SN.title;
  }
  return siteName;
};

/**
 * Get the threshold for accordeons when they collaps
 * Used in list, review list, partner location
 * @param type undefined | locations | review - which threshold should be returned
 */
const getCollapseThreshold = (type) => {
  let threshold = 4;
  if ('review' === type) {
    threshold = 2;
  }
  if ('locations' === type) {
    threshold = 7;
  }
  return parseInt(threshold);
};

/**
 *
 * @param wpApi wpapi.WPAPI
 */
const setHeaders = (wpApi) => {
  if ('production' === process.env.NODE_ENV) {
    wpApi.setHeaders({
      'Cache-Control':
        'public, max-age=' +
        ('undefined' !== typeof process.env.REACT_APP_CACHE_MAX_AGE ? process.env.REACT_APP_CACHE_MAX_AGE : 14400),
      Pragma: 'public',
    });
  }
};

const renderTitle = (text, withSiteName) => {
  withSiteName = withSiteName !== false;
  if (text && text.rendered) {
    text = text.rendered;
  }
  if (withSiteName) {
    text = text + ' - ' + getSiteName();
  }
  if (text) {
    return (
      <Helmet>
        <title>{Parser(text)}</title>
      </Helmet>
    );
  } else {
    return null;
  }
};

const renderStyles = (style) => {
  return (
    <Helmet>
      <style>{style}</style>
    </Helmet>
  );
};

/**
 * Remove existing meta description tag from head
 *
 * @returns void
 */

const removeMetaDesc = () => {
  const metaTag = document.querySelector(`meta[name="description"]`);
  if (metaTag) {
    metaTag.remove();
  }
};

const renderMetaDesc = (description) => {
  removeMetaDesc();

  if (!description) {
    return null;
  }

  return (
    <Helmet>
      <meta name="description" content={description} />
    </Helmet>
  );
};

const replaceCallback = (domNode) => {
  if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {
    const href = domNode.attribs.href;

    // do nothing if href has param privacyaction or target attr is _blank
    // '_blank' === target ||
    if (/privacyaction=/i.test(href)) {
      return domNode;
    }
    const attr = attributesToProps(domNode.attribs);
    return <UniLink {...attr}>{domToReact(domNode.children)}</UniLink>;

    // embeded Youtube / Vimeo Video
  } else if (
    domNode.name === 'iframe' &&
    domNode.attribs &&
    domNode.attribs.src &&
    /youtube|vimeo/i.test(domNode.attribs.src)
  ) {
    // search the thumbnail
    let found = false;
    let search = domNode.parent;
    let thumbnail = false;
    if (search) {
      do {
        if (
          search.attribs &&
          search.attribs.class.split(' ').indexOf('entry-content-asset') !== -1 &&
          search.attribs['data-img']
        ) {
          found = true;
          thumbnail = search.attribs['data-img'];
        }
        search = search.parent ? search.parent : false;
      } while (search && !found);
    }

    if (/vimeo/i.test(domNode.attribs.src)) {
      return <VimeoIFrame {...domNode} thumbnail={thumbnail} />;
    } else {
      return <YoutubeIFrame attribs={attributesToProps(domNode.attribs)} thumbnail={thumbnail} />;
    }
  } else if (
    domNode.name === 'div' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(' ').indexOf('wp-block-column') !== -1
  ) {
    const childNodes = domNode.children;
    const attribs = domNode.attribs;
    attribs.class = attribs.class.replace('wp-block-column', '').concat(' ida-wp-block-column');
    return <div {...attributesToProps(attribs)}>{domToReact(childNodes, parserOptions)}</div>;
  } else if (
    domNode.name === 'iframe' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(' ').indexOf('ida_prescription_form_embed_frame') !== -1
  ) {
    return <FormIFrame key={'wrapper_' + domNode.attribs.id} {...attributesToProps(domNode.attribs)} />;
  } else if (domNode.name === 'form' && domNode.attribs) {
    return <Form attribs={attributesToProps(domNode.attribs)}>{domToReact(domNode.children, parserOptions)}</Form>;
  } else if (domNode.name === 'input') {
    const node = domNode;
    const { value, ...attribs } = domNode.attribs;
    if (undefined !== value && 'submit' !== attribs.type) {
      if ('radio' === attribs.type) {
        const checked = attribs.checked;
        delete attribs.checked;

        return <input defaultChecked={checked} defaultValue={value} {...attributesToProps(attribs)} />;
      }
      return <input defaultValue={value} {...attributesToProps(attribs)} />;
    } else {
      return node;
    }
  } else if (
    domNode.name === 'span' &&
    domNode.attribs &&
    domNode.attribs.class &&
    domNode.attribs.class.split(' ').indexOf('wpcf7-form-control-wrap') !== -1 &&
    !domNode.attribs['data-name']
  ) {
    const childNodes = domNode.children;
    const { ...attribs } = domNode.attribs;
    const dataName = attribs.class.split(' ')[1];
    return (
      <span {...attributesToProps(attribs)} data-name={dataName}>
        {domToReact(childNodes, parserOptions)}
      </span>
    );
  } else if (-1 !== ['table', 'tbody', 'tr'].indexOf(domNode.name)) {
    const childNodes = domNode.children;
    const cleanedNotes = childNodes.filter((node) => 'text' !== node.type);
    domNode.children = cleanedNotes;
    return domNode;
  }
};

const parserOptions = {
  replace: replaceCallback,
};

const renderText = (text) => {
  if (text) {
    if (text.rendered) {
      text = text.rendered;
    }
    return Parser(text, parserOptions);
  } else {
    return null;
  }
};

const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

const serializeObjToQueryString = (params) =>
  Object.keys(params)
    .map((k, i) => (0 === i && '?') + k + '=' + encodeURIComponent(params[k]))
    .join('&');

const parseUrlComponents = (url) => {
  var parser;
  //const location= getLocation();
  var base = getLocation();

  try {
    parser = new URL(url, base);
  } catch (e) {
    parser = new URL(base);
  }
  return parser;
  //parser = document.createElement('a');
  //parser.href = url;
  /*return {
    protocol: parser.protocol,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    hash: parser.hash,
    host: parser.host,
  };*/
};

const getLocation = () => {
  if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
    return window.location;
  }
  return null;
};

const isCurrentHost = (url) => {
  const hostname = window?.location.hostname;
  const urlComponents = parseUrlComponents(url);
  return hostname === urlComponents.hostname;
};

const isPortalPage = () => {
  const subdomain = getSubdomain();
  const location = getLocation();
  const queryParams = getQueryStringParams(location.search);

  return !subdomain && !queryParams.subdomain && 'www' !== subdomain;
};

/**
 *
 * @param {object} trackingParams
 * @param {string} suffix
 * @returns
 */
const trackingClassName = (trackingParams, suffix) => {
  return `ga-${trackingParams.partnerSlug}-${trackingParams.postType.replace('sanoa_', '')}-${
    trackingParams.slug
  }-${suffix}`;
};

export {
  getMainDomain,
  getHomeUrl,
  getSubdomain,
  getEndpointUrl,
  getApiconfig,
  getSiteName,
  getConfig,
  setHeaders,
  renderTitle,
  renderText,
  renderStyles,
  renderMetaDesc,
  parserOptions,
  getQueryStringParams,
  serializeObjToQueryString,
  parseUrlComponents,
  getLocation,
  isCurrentHost,
  getCollapseThreshold,
  isPortalPage,
  trackingClassName,
};
