import React from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';

/**
 * youtube iframe component with blocker for iframe replacement in react html parser
 * for wp-embeded youtube videos
 * @see renderText
 * @see parserOptions
 */

const srcWithParams = (src) => {
  // use nocookie url, add parameters
  // rel: only related videos from the same channel
  // cc_load_policy: always show subtitles
  const parameters = 'rel=0&cc_load_policy=1';

  if (src) {
    const separator = src.indexOf('?') === -1 ? '?' : '&';
    return src.replace('youtube.com/embed/', 'youtube-nocookie.com/embed/') + separator + parameters;
  }
  return '';
};

const YoutubeIFrame = inject('blockDataStore')(
  observer((props) => {
    const allowYoutube = props.blockDataStore.allowYoutube;
    if (!allowYoutube) {
      return (
        <BlockButton
          provider="youtube"
          thumbnail={props.thumbnail}
          onClick={props.blockDataStore.onClickEnableOption}
        />
      );
    } else {
      const { title, src, ...attribs } = props.attribs;
      return <iframe title={title} src={srcWithParams(src)} {...attribs}></iframe>;
    }
  })
);

export default YoutubeIFrame;
