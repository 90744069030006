import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { parseUrlComponents, isPortalPage } from '../../../helpers/api';
import { RouterContext } from '../../../stores/RouterContext';

const UniLink = (props) => {
  const { title, href, target, className, children, tracking, ...attribs } = props;
  const urlComponents = parseUrlComponents(href);
  const hostname = window?.location.hostname;
  const routerContext = useContext(RouterContext);

  /**
   * tracking param can be set to false to disable tracking
   * or { value: 'portal', disable: true }
   * @param {string} url
   * @returns string
   */
  const addTrackingParams = (url) => {
    if (false === tracking || tracking?.disable) {
      return url;
    }
    if (undefined !== tracking?.value) {
      if (Array.isArray(tracking?.value)) {
        tracking.value.map((value) => urlComponents.searchParams.append('utm_content', value));
      } else {
        urlComponents.searchParams.append('utm_content', tracking?.value);
      }
    }

    // fixed values
    urlComponents.searchParams.append('utm_id', 'u0cers');
    urlComponents.searchParams.append('utm_medium', 'sanoa_external');
    urlComponents.searchParams.append('utm_campaign', 'sanoa.ext.links');
    urlComponents.searchParams.append('utm_term', 'sanoa');

    const source = [];
    if (!isPortalPage) {
      routerContext.partnerSlug && source.push(routerContext.partnerSlug);
      routerContext.locationSlug && source.push(routerContext.locationSlug);
    }
    routerContext.postType && source.push(routerContext.postType.replace('sanoa_', ''));
    routerContext.slug && source.push(routerContext.slug);
    urlComponents.searchParams.append('utm_source', source.join('-'));

    return urlComponents.toString();
  };

  if (hostname === urlComponents.hostname && !target) {
    return (
      <Link
        className={className ? className : undefined}
        to={{ pathname: urlComponents.pathname, search: urlComponents.search, hash: urlComponents.hash }}
        title={title}
        {...attribs}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={className ? className : undefined}
        href={addTrackingParams(href)}
        target={target ? target : undefined}
        title={title}
        {...attribs}
        rel={target ? 'noopener noreferrer' : attribs.rel}
      >
        {children}
      </a>
    );
  }
};

export default UniLink;
