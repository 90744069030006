import React from 'react';
import { inject, observer } from 'mobx-react';
import { renderText } from '../../../helpers/api';
import UniLink from '../../elements/UniLink/UniLink';
import Img from '../../elements/Img/Img';

/**
 * ACF Teaser with Image, Text and Link
 * single or as list
 */

const ACFTeaser = inject('windowStore')(
  observer((props) => {
    const windowStore = props.windowStore;
    const smallImage = windowStore.isSmallerThan('sm');
    let list = [];
    if (props.content.mink_fc_teaser_items) {
      list = props.content.mink_fc_teaser_items;
    } else {
      list = [
        {
          text: props.content.mink_fc_teaser_text,
          img: props.content.mink_fc_teaser_img,
          link: props.content.mink_fc_teaser_link,
        },
      ];
    }

    return (
      <div className={'acf-teaser__list' + (props.content.mink_fc_teaser_separator ? ' -seperator' : '')}>
        {list &&
          list.map((item, i) => {
            return (
              <div key={'acf-teaser-' + i}>
                {item.text && item.img && (
                  <div className="acf-teaser" key={'acf-teaser-' + i}>
                    <div className="acf-teaser__img">
                      {item.link?.url ? (
                        <UniLink href={item.link.url} target={item.link.target}>
                          <Img
                            src={smallImage ? item.img?.sizes?.small : item.img?.sizes?.medium}
                            width={smallImage ? item.img?.sizes?.smallWidth : item.img?.sizes?.mediumWidth}
                            height={smallImage ? item.img?.sizes?.smallHeight : item.img?.sizes?.mediumHeight}
                            alt={item.img?.alt}
                            title={item.img?.title}
                          />
                        </UniLink>
                      ) : (
                        <Img
                          src={smallImage ? item.img?.sizes?.small : item.img?.sizes?.medium}
                          width={smallImage ? item.img?.sizes?.smallWidth : item.img?.sizes?.mediumWidth}
                          height={smallImage ? item.img?.sizes?.smallHeight : item.img?.sizes?.mediumHeight}
                          alt={item.img?.alt}
                          title={item.img?.title}
                        />
                      )}
                    </div>
                    <div className="acf-teaser__text">
                      {item.subtitle && <h6>{renderText(item.subtitle)}</h6>}
                      {item.title && <div className="-head">{renderText(item.title)}</div>}
                      <div className="-text">{renderText(item.text)}</div>
                      {item.link?.url && (
                        <div className="-link-wrapper">
                          <div className="-link">
                            <UniLink href={item.link.url} target={item.link.target}>
                              {renderText(item.link.title)}
                            </UniLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  })
);

export default ACFTeaser;
