import React, { useContext } from 'react';
import { CollapseContext } from './CollapseContext';

/**
 * Toggles the entries configured with the threshold param
 * used in
 * - ACF Partner Locations,
 * - ACF List,
 * - ACF Review Location List
 * - api
 */

const CollapseTogglerMore = ({ moreCount }) => {
  const collapseContext = useContext(CollapseContext);
  const toggleClass = collapseContext.open ? 'open' : 'closed';

  return (
    <div className="mink-collapse-box__toggler">
      <button className={'mink-collapse-box__button ' + toggleClass} onClick={collapseContext.toggleOpen}>
        {!collapseContext.open ? moreCount + ' weitere' : ''}
      </button>
    </div>
  );
};

export default CollapseTogglerMore;
