import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import BlockButton from './BlockButton';
import { MarkerF, InfoWindowF } from '@react-google-maps/api';
import StyledMap, { getIcon } from './StyledMap';

/* React 18+ might have to use MarkerF instead of Marker to work */

const LocationMap = inject('blockDataStore')(
  observer((props) => {
    const post = props.post;
    const location = props.post.location;
    const [selectedMarker, setSelectedMarker] = useState(null);

    const clickMap = (obj) => {
      if (obj) {
        setSelectedMarker(null);
      }
    };
    const clickMarker = (slug) => {
      setSelectedMarker(slug);
    };
    const onCloseInfo = (e) => {
      setSelectedMarker(null);
    };

    const mapsLocation = location.bi_data.latlng;
    const primaryColor = post.partner?.primary_color;
    const allowed = props.blockDataStore.allowGoogleMaps;
    if (!mapsLocation) {
      return null;
    }
    if (!allowed) {
      return <BlockButton onClick={props.blockDataStore.onClickEnableOption} />;
    }

    /* pass isVisible from VisibilitySensor */
    return (
      <StyledMap isVisible={props.isVisible} center={mapsLocation} zoom={15} onClick={clickMap}>
        {Array.isArray(post.partner_locations) &&
          post.partner_locations.map((loc) => {
            const isCurrentLocation = location.slug === loc.slug;
            const addressQuery = encodeURIComponent(loc.title + ' ' + loc.street + ',' + loc.zip + ' ' + loc.city);
            if (!loc.latlng) {
              return null;
            }

            return (
              <MarkerF
                key={loc.slug}
                position={loc.latlng}
                icon={isCurrentLocation ? getIcon(primaryColor, 1.2) : getIcon(primaryColor, 0.8)}
                label={
                  isCurrentLocation ? { text: location.name, className: 'mink-google-map__marker-label' } : undefined
                }
                onClick={() => clickMarker(loc.slug)}
                title={loc.title}
              >
                {selectedMarker && selectedMarker === loc.slug && (
                  <InfoWindowF position={loc.latlng} onCloseClick={onCloseInfo}>
                    <div className="mink-google-map__info-window">
                      <p className="-title">
                        <strong>{loc.title}</strong>
                      </p>
                      <p>
                        {loc.street}
                        <br />
                        {loc.zip} {loc.city}
                      </p>
                      <p>
                        {!isCurrentLocation && (
                          <>
                            <a href={loc.urlc.path}>Zum Standort</a>
                            <br />
                          </>
                        )}
                        <a
                          href={'https://www.google.com/maps/search/?api=1&query=' + addressQuery}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Zu Google Maps
                        </a>
                      </p>
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            );
          })}
      </StyledMap>
    );
  })
);

export default LocationMap;
