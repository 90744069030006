import React, { useEffect, useContext } from 'react';
import { inject, observer } from 'mobx-react';
import { renderPostFields } from '../../helpers/render';
import { renderTitle, renderMetaDesc } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import ScrollToHash from '../../components/nav/ScrollToHash/ScrollToHash';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import CloseBtn from '../../components/nav/Close/CloseBtn';
import CloseBtnText from '../../components/nav/Close/CloseBtnText';
import { RouterContext } from '../../stores/RouterContext';

/**
 * wrapper component represents single post
 */
const Post = inject('pagesStore')(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const location = routerContext.location;
    const slug = routerContext.slug;
    const locationSlug = routerContext.locationSlug;
    const partnerSlug = routerContext.partnerSlug;
    const store = props.store ? props.store : props.pagesStore;
    const key = locationSlug ? locationSlug + '-' + slug : slug;
    const isFetched = key && store.isFetched.get(key);

    useEffect(() => {
      if (slug) {
        if (props.apiParams && props.apiParams.postType) {
          store.fetchPathIfNeeded(slug);
        } else {
          store.fetchIfNeeded(slug, locationSlug, partnerSlug);
        }

        document.body.classList.add('page', 'single-page');
        if (isFetched) {
          const postType = store.data.posts[key].post_type;
          document.body.classList.add(postType);
        }

        return () => {
          document.body.classList.remove('page', 'single-page');
          if (store.isFetched.get(key)) {
            const postType = store.data.posts[key].post_type;
            document.body.classList.remove(postType);
          }
        };
      }
    }, [props.apiParams, slug, store, isFetched, locationSlug, partnerSlug, key]);

    const renderHead = () => {
      if (isFetched && store.data.posts[key].title) {
        return renderTitle(store.data.posts[key].title);
      } else {
        return null;
      }
    };

    const handleRenderMetaDesc = () => {
      if (isFetched && store.data.posts[key].meta_desc) {
        const post = store.data.posts[key];
        const desc = post.meta_desc;
        return renderMetaDesc(desc);
      } else {
        return null;
      }
    };

    const post = store.data.posts[key];
    const scrollToTop = !location.hash && (!location.state || !location.state.scrollTo);

    if ((isFetched && false === post) || (store.isFetchedAll && !post)) {
      return <NoMatch />;
    }

    return (
      <div className="ida-page">
        {isFetched ? (
          <>
            {scrollToTop && <ScrollToTop onHistoryPush={true} />}
            <ScrollToHash />
            {renderHead()}
            {handleRenderMetaDesc()}
            <CloseBtn />
            <div className="ida-content" data-test={post.post_type}>
              <div className="container">
                <div className="row">
                  <div className="col-12">{renderPostFields(post)}</div>
                </div>
              </div>
            </div>
            <div className="mink-popup__footer">
              <CloseBtnText />
            </div>
          </>
        ) : (
          <LoadingAnimation />
        )}
      </div>
    );
  })
);

export default Post;
