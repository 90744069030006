import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { renderText, getConfig, isPortalPage } from '../../../helpers/api';

const ConsentModal = observer((props) => {
  const { blockDataStore, trackingStore } = useContext(MobXProviderContext);

  if (!blockDataStore.isActiveTracking || blockDataStore.disableConsentModal) {
    return null;
  }

  const onCLick = (option) => (e) => {
    trackingStore.saveConsentModalData(option);
    blockDataStore.onClickAnalyticsOption(option, e);
  };

  const config = getConfig('tracking');

  const text = config.mink_opt_tracking_text ? config.mink_opt_tracking_text : null;

  let footer = null;
  if (isPortalPage() && config.mink_opt_tracking_corporate_footer) {
    footer = config.mink_opt_tracking_corporate_footer;
  } else if (config.mink_opt_tracking_footer) {
    footer = config.mink_opt_tracking_footer;
  }

  const yes = config.mink_opt_tracking_yes ? config.mink_opt_tracking_yes : null;
  const no = config.mink_opt_tracking_no ? config.mink_opt_tracking_no : null;
  const isOpen = blockDataStore.showConsentModal;

  return (
    <>
      {isOpen && (
        <div className={'mink-consent-modal__root' + (isOpen ? '' : ' d-none')} tabindex="-1">
          <div className="modal show d-block" aria-labelledby="consent" aria-modal="true" role="dialog" tabindex="-1">
            <div
              id="consent"
              aria-label="Privatsphäre Einstellungen"
              className="modal-dialog mink-consent-modal modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="mink-consent-modal__body modal-body">{renderText(text)}</div>
                <div className="mink-consent-modal__footer px-0 modal-footer">
                  <button type="button" className="btn btn-light" onClick={onCLick(false)}>
                    {renderText(no)}
                  </button>
                  <button type="button" className="btn btn-primary" onClick={onCLick(true)}>
                    <span className="-text">{renderText(yes)}</span>
                  </button>
                </div>
                <div className="mink-consent-modal__body modal-body">{renderText(footer)}</div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show"></div>
        </div>
      )}
    </>
  );
});

export default ConsentModal;
