import React from 'react';
import { getCollapseThreshold, renderText } from '../../../helpers/api';
import ShowMoreItems from '../../elements/ShowMoreItems/ShowMoreItems';

/**
 * ACF Flexible Content Field
 * generates a list of all locations from the current partner with review link
 * @field mink_fc_review_list
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#Embed-review-location-list
 */

const ACFReviewLocationsList = (props) => {
  const partnerLocations = props.content.review_locations;

  const locations = partnerLocations.length
    ? [...partnerLocations.filter((location) => location.google_review_link)]
    : [];

  const t = getCollapseThreshold('review');
  const locationsCollapsed = t + 1 < locations.length ? locations.splice(t) : [];

  const gaLocationRateClassName =
    'ga-' + props.partner + '-' + props.post_type.replace('sanoa_', '') + '-' + props.slug + '-review-now';

  const Location = ({ location }) => {
    return (
      <div className="acf-review-location-list">
        <div className="acf-review-location-list__img">
          <img alt={location.title} src={location.location_thumbnail[0]} />
        </div>
        <div className="acf-review-location-list__text">
          <p>
            <strong>{renderText(location.title)}</strong>
          </p>
          <p>{renderText(location.street + ', ' + location.zip + ' ' + location.city)}</p>
          <div className="acf-review-location-list__button">
            <a
              href={location.google_review_link}
              target="_blank"
              rel="noopener noreferrer"
              className={'btn-cta ' + gaLocationRateClassName}
            >
              Jetzt bewerten
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mink-collapse-box">
        <div className="mink-collapse-box__content">
          {locations.map((location) => {
            return <Location key={location.id} location={location} />;
          })}
          <ShowMoreItems moreCount={locationsCollapsed.length}>
            {locationsCollapsed.map((location) => {
              return <Location key={location.id} location={location} />;
            })}
          </ShowMoreItems>
        </div>
      </div>
    </>
  );
};
export default ACFReviewLocationsList;
