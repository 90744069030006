import { action, observable, makeObservable } from 'mobx';
import { getQueryStringParams, serializeObjToQueryString, getConfig } from '../../helpers/api';

/**
 * handle privacy settings
 * using a block button for each component, so user can decide individually
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/278888459/Tracking+Analysis
 */

export default class BlockDataStore {
  expiresAfterDays = 7;
  storageKey = 'sanoa.userSettings';
  itemsToStore = ['analyticsWasSet', 'allowAnalytics', 'analyticsDate'];

  userSettings = {
    allowYoutube: false,
    allowGoogleMaps: false,
    allowVimeo: false,
    allowBookingTime: false,
    allowAppointments: false,
    allowAnalytics: false,
    analyticsWasSet: false,
    analyticsDate: null,
    allowThirdPartyApp: false,
  };

  allowedQueryParamValues = {
    disableGoogleMaps: 'allowGoogleMaps',
    disableYoutube: 'allowYoutube',
    disableVimeo: 'allowVimeo',
    disableBookingTime: 'allowBookingTime',
    disableBookingTerm: 'allowAppointments',
    disableAnalytics: 'allowAnalytics',
    disableThirdPartyApp: 'allowThirdPartyApp',
  };

  allowYoutube = false;
  allowVimeo = false;
  allowGoogleMaps = false;
  allowBookingTime = false;
  allowAppointments = false;
  allowAnalytics = false;
  showConsentModal = false;
  isActiveTracking = false;
  allowThirdPartyApp = false;
  disableAnalyticsOnUnmount = false;

  constructor(routerStore, navigationStore) {
    makeObservable(this, {
      allowYoutube: observable,
      allowVimeo: observable,
      allowGoogleMaps: observable,
      allowBookingTime: observable,
      allowAppointments: observable,
      allowAnalytics: observable,
      allowThirdPartyApp: observable,
      showConsentModal: observable,
      isActiveTracking: observable,
      init: action,
      setSetting: action,
      setIsActiveTracking: action,
      setShowConsentModal: action,
      onClickAnalyticsOption: action,
      setAllowAnalyticsTemp: action,
      setAllowGoogleMapsTemp: action,
      maybeDisableAllowGoogleMapsTemp: action,
      maybeDisableAllowAnalyticsTemp: action,
    });
    this.routerStore = routerStore;
    this.navigationStore = navigationStore;
    this.init();
  }

  init = () => {
    try {
      if (localStorage) {
        const userSettings = JSON.parse(localStorage.getItem(this.storageKey));
        // maybe remove this after some time
        localStorage.removeItem('ida.userSettings');
        this.maybeDisableOption();
        if (userSettings) {
          this.userSettings = { ...this.userSettings, ...userSettings };
          if (this.userSettings && this.userSettings.allowAnalytics && true === this.userSettings.allowAnalytics) {
            this.allowAnalytics = true;
          }
          /*
          if (this.userSettings && this.userSettings.allowYoutube && true === this.userSettings.allowYoutube) {
            //this.allowYoutube = true;
          }
          if (this.userSettings && this.userSettings.allowVimeo && true === this.userSettings.allowVimeo) {
            //this.allowVimeo = true;
          }
          if (this.userSettings && this.userSettings.allowGoogleMaps && true === this.userSettings.allowGoogleMaps) {
            // this.allowGoogleMaps = true;
          }
          if (this.userSettings && this.userSettings.allowBookingTime && true === this.userSettings.allowBookingTime) {
            //this.allowBookingTime = true;
          }
          if (
            this.userSettings &&
            this.userSettings.allowAppointments &&
            true === this.userSettings.allowAppointments
          ) {
            //this.allowAppointments = true;
          }*/
        }
      }
      this.setShowConsentModal();
      this.setIsActiveTracking();
    } catch (e) {
      console.info('no local storage');
    }
  };

  /**
   * check for a privacyaction param in query string and if it has an allowed value
   * if so set this option to false
   */
  maybeDisableOption = () => {
    const search = this.routerStore.location.search;
    const params = getQueryStringParams(search);
    if (params.privacyaction && this.allowedQueryParamValues.hasOwnProperty(params.privacyaction)) {
      const option = this.allowedQueryParamValues[params.privacyaction];
      this.disableOption(option);
      // now remove query param and push new path
      const { privacyaction, ...reducedParams } = params;
      const search = serializeObjToQueryString(reducedParams);
      this.routerStore.location.replace('/' + search);
    }
  };

  /**
   * @param {string}  option
   */
  enableOption = (option) => {
    this.setSetting(option, true);
  };

  /**
   * @param {string}  option
   */
  disableOption = (option) => {
    if ('allowAnalytics' === option) {
      this.setAnalyticsDate();
      this.setSetting('analyticsWasSet', true);
    }
    this.setSetting(option, false);
  };

  /**
   * set an option in user settings and store in localStorage
   * @param {string}  option
   * @param {boolean} value
   */
  setSetting = (option, value) => {
    if (undefined !== this.userSettings[option]) {
      if (undefined === value) {
        value = true;
      }
      this.userSettings[option] = value;
      if (undefined !== this[option]) {
        this[option] = value;
      }
      try {
        // analyticsWasSet, allowAnalytics, analyticsDate
        if (localStorage && this.itemsToStore.includes(option)) {
          const items = Object.keys(this.userSettings)
            .filter((key) => this.itemsToStore.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.userSettings[key];
              return obj;
            }, {});
          localStorage.setItem(this.storageKey, JSON.stringify(items));
        }
      } catch (e) {
        console.info('no local storage');
      }
    }
  };

  /**
   * Click Handler
   * @param {Event} e
   * @returns bool
   */
  onClickEnableOption = (option, e) => {
    this.enableOption(option);
    e.preventDefault();
    return false;
  };

  onClickAnalyticsOption = (option, e) => {
    let value = false;
    if (true === option) {
      value = true;
    }

    this.setSetting('allowAnalytics', value);
    this.setSetting('analyticsWasSet', true);
    this.setAnalyticsDate();
    this.setShowConsentModal();

    e.preventDefault();
    return false;
  };

  setAnalyticsDate = () => {
    const ts = this.navigationStore.ts;
    const date = ts ? new Date(parseInt(ts, 10)) : new Date();
    this.setSetting('analyticsDate', date.getTime());
  };

  getIsExpired = (date) => {
    const ts = this.navigationStore.ts;
    const expiresAfterDays = this.expiresAfterDays;
    const now = ts ? new Date(parseInt(ts, 10)) : new Date();
    const expirationDate = ((d) => new Date(d.setDate(d.getDate() - expiresAfterDays)))(now);

    return new Date(date) < expirationDate;
  };

  setShowConsentModal = () => {
    if (
      !this.disableConsentModal &&
      true !== this.allowAnalytics &&
      (!this.userSettings.analyticsDate || !this.userSettings.analyticsWasSet || undefined === this.allowAnalytics)
    ) {
      this.showConsentModal = true;
      document.body.classList.add('modal-open');
    } else if (
      !this.disableConsentModal &&
      !this.allowAnalytics &&
      this.getIsExpired(this.userSettings.analyticsDate)
    ) {
      this.showConsentModal = true;
      document.body.classList.add('modal-open');
    } else {
      this.showConsentModal = false;
      document.body.classList.remove('modal-open');
    }
  };

  setIsActiveTracking = () => {
    const config = getConfig('tracking');
    if (config && undefined !== config.mink_opt_tracking_active) {
      this.isActiveTracking = config.mink_opt_tracking_active;
    } else {
      this.isActiveTracking = false;
    }
  };

  setAllowAnalyticsTemp = () => {
    if (!this.allowAnalytics) {
      this.allowAnalytics = true;
      this.disableAnalyticsOnUnmount = true;
    }
  };

  maybeDisableAllowAnalyticsTemp = () => {
    if (this.disableAnalyticsOnUnmount) {
      this.allowAnalytics = false;
    }
  };

  setAllowGoogleMapsTemp = () => {
    if (!this.allowGoogleMaps) {
      this.allowGoogleMaps = true;
      this.disableGoogleMapsOnUnmount = true;
    }
  };

  maybeDisableAllowGoogleMapsTemp = () => {
    if (this.disableGoogleMapsOnUnmount) {
      this.allowGoogleMaps = false;
    }
  };
}
