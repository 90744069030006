import React, { useEffect, useContext, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { renderPostFields } from '../../helpers/render';
import { renderTitle, renderMetaDesc } from '../../helpers/api';
import NoMatch from '../NoMatch/NoMatch';
import LoadingAnimation from '../../components/nav/LoadingAnimation/LoadingAnimation';
import { RouterContext } from '../../stores/RouterContext';

/**
 * wrapper component represents single post
 */
const Embed = inject(
  'embedStore',
  'windowStore',
  'blockDataStore'
)(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const slug = routerContext.slug;
    const store = props.embedStore;
    const windowStore = props.windowStore;
    const blockDataStore = props.blockDataStore;
    const isFetched = slug && store.isFetched.get(slug);
    const containerRef = useRef(null);
    const post = store.data.posts[slug];

    useEffect(() => {
      if (slug) {
        store.fetchIfNeeded(slug);

        document.body.classList.add('embed');
        if (isFetched) {
          const postType = store.data.posts[slug].post_type;
          document.body.classList.add(postType);
        }
        const currentSlug = slug;

        return () => {
          document.body.classList.remove('embed');
          if (store.isFetched.get(currentSlug)) {
            const postType = store.data.posts[currentSlug].post_type;
            document.body.classList.remove(postType);
          }
        };
      }
    }, [props.apiParams, slug, store, isFetched]);

    useEffect(() => {
      if (!windowStore.containerRef?.current) {
        windowStore.startResizeObserver(containerRef);
      }
      return () => {
        windowStore.stopResizeObserver();
      };
    }, [windowStore]);

    useEffect(() => {
      blockDataStore.disableConsentModal = true;
      blockDataStore.setShowConsentModal();
    }, [blockDataStore]);

    useEffect(() => {
      if (!blockDataStore.allowAnalytics && post?.tracking_active) {
        blockDataStore.setAllowAnalyticsTemp();
      }
      if (!blockDataStore.allowGoogleMaps && post?.disable_block_button) {
        blockDataStore.setAllowGoogleMapsTemp();
      }
      return () => {
        blockDataStore.maybeDisableAllowAnalyticsTemp();
        blockDataStore.maybeDisableAllowGoogleMapsTemp();
      };
    }, [blockDataStore, post]);

    const renderHead = () => {
      if (isFetched && store.data.posts[slug].title) {
        return renderTitle(store.data.posts[slug].title);
      } else {
        return null;
      }
    };

    const handleRenderMetaDesc = () => {
      if (isFetched && store.data.posts[slug].meta_desc) {
        const post = store.data.posts[slug];
        const desc = post.meta_desc;
        return renderMetaDesc(desc);
      } else {
        return null;
      }
    };

    // send a message to the parent of emded
    if (windowStore.scrollHeight) {
      const message = {
        height: windowStore.scrollHeight,
        width: windowStore.scrollWidth,
      };
      // window.top refers to parent window
      window.top.postMessage(message, '*');
    }

    if ((isFetched && false === post) || (store.isFetchedAll && !post)) {
      return <NoMatch ref={containerRef} />;
    }

    return (
      <div ref={containerRef} className="mink-embed-container">
        {isFetched ? (
          <>
            {renderHead()}
            {handleRenderMetaDesc()}
            {renderPostFields(post)}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </div>
    );
  })
);

export default Embed;
