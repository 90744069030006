import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import PortalMenu from '../../nav/PortalMenu/PortalMenu';
import useEventListener from '@use-it/event-listener';

/**
 * Header for Portal
 */
const HeaderPortal = inject(
  'windowStore',
  'portalStore',
  'navigationStore',
  'routerStore'
)(
  observer((props) => {
    const post = props.post;
    const [visible, setVisible] = useState(false);

    const onWindowScroll = () => {
      if (window?.scrollY > 100) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    useEventListener('scroll', onWindowScroll, window, { passive: true });

    return (
      <header className={'sn-portal__header' + (visible ? ' -scrolled' : '')}>
        <div className="sn-portal__teaser">
          <div className="container">
            <a href="https://www.sanoa.info/" target="_blank" rel="noopener noreferrer">
              Sanoa für Sanitätshausunternehmen
            </a>
          </div>
        </div>

        <PortalMenu items={post.navigation_moderated} depthLevel={0} rootElement={props.rootElement} />
      </header>
    );
  })
);
export default HeaderPortal;
