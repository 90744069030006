import React from 'react';
import { ReactComponent as IconFacebook } from '../../../icons/icon-facebook.svg';
import { ReactComponent as IconInstagram } from '../../../icons/icon-instagram.svg';
import { ReactComponent as IconLinkedin } from '../../../icons/icon-linkedin.svg';
import { ReactComponent as IconWhatsapp } from '../../../icons/icon-whatsapp.svg';
import { ReactComponent as IconYoutube } from '../../../icons/icon-youtube.svg';
import UniLink from '../../elements/UniLink/UniLink';

const getIconSvg = (id) => {
  switch (id) {
    case 'whatsapp_number':
      return <IconWhatsapp />;
    case 'linkedin_link':
      return <IconLinkedin />;
    case 'youtube_link':
      return <IconYoutube />;
    case 'facebook_link':
      return <IconFacebook />;
    case 'instagram_link':
      return <IconInstagram />;
    default:
      break;
  }
};

const HeaderNavSocial = ({ post, socialData }) => {
  const socialMenu = [];
  if (socialData) {
    const socialIDs = [
      ['whatsapp_number', 'Whatsappkanal von '],
      ['linkedin_link', 'LinkedIn Seite von '],
      ['youtube_link', 'YouTube Seite von '],
      ['facebook_link', 'Facebook Seite von '],
      ['instagram_link', 'Instagram Seite von '],
    ];
    socialIDs.forEach((idEntry) => {
      const id = idEntry[0];
      const title = idEntry[1] + post?.partner?.name;
      const entry = socialData[id];

      if (entry) {
        if ('whatsapp_number' === id) {
          socialMenu.push({ id: id, title: title, url: entry });
        } else {
          if (entry.url) {
            socialMenu.push({ id: id, title: title, url: entry.url });
          }
        }
      }
    });
  }

  return (
    <>
      {socialMenu.length > 0 && (
        <div className="social-icons">
          {socialMenu.map((item) => {
            return (
              <UniLink key={item.id} href={item.url} target="_blank" title={item.title} className="social-link">
                {getIconSvg(item.id)}
              </UniLink>
            );
          })}
        </div>
      )}
    </>
  );
};

export default HeaderNavSocial;
