import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Img from '../../elements/Img/Img';
import DaysHours from '../DaysHours/DaysHours';
import { trackingClassName, renderText } from '../../../helpers/api';
import { observer, inject } from 'mobx-react';
import CollapseSimple from '../../elements/Collapse/CollapseSimple';
import { RouterContext } from '../../../stores/RouterContext';
import UniLink from '../../elements/UniLink/UniLink';

const LocationBox = inject(
  'phoneStore',
  'navigationStore',
  'windowStore'
)(
  observer((props) => {
    const routerContext = useContext(RouterContext);
    const locationPath = routerContext.locationPath + '/';

    const post = props.post;
    const partner = post.partner;
    const location = post.location;
    const actionbuttons = post.partner.action_buttons;
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const isCurrentUrl = props.navigationStore.isCurrentUrl;
    const trackingParams = {
      partnerSlug: post.partner.slug,
      postType: post.post_type,
      slug: post.slug,
    };

    const getLogo = () => {
      const title = partner?.name + ' ' + location?.city;
      const alt = partner?.name + ' ' + location?.city;
      if (post.location?.member) {
        const logoObj = post.location.logo;
        if (logoObj) {
          return (
            <Img
              src={logoObj.url}
              width={logoObj.width}
              height={logoObj.height}
              className="img-fluid"
              alt={alt}
              title={title}
            />
          );
        } else {
          return null;
        }
      } else {
        return (
          <img
            className="img-fluid"
            src={'/content/themes/sanoa/assets/images/sanoa-logo-fallback.webp'}
            width={512}
            height={512}
            alt={alt}
            title={title}
          />
        );
      }
    };

    const getAddress = () => {
      return post.location.address.map((row, i) => {
        return <div key={i}>{renderText(row)}</div>;
      });
    };

    const toggleContactDetails = () => {
      setIsDetailsOpen((prevState) => !prevState);
    };

    const currentLocationBoxPhone = props.phoneStore.currentLocationBoxPhone;
    const phone = currentLocationBoxPhone ? currentLocationBoxPhone : location.phone;
    const additionalHoursText = location.additionalHoursText
      ? location.additionalHoursText
      : location.text_opening_hours;
    const hasOpeningHours = Object.keys(location.formatedOpeningHours).length > 0;
    const addressQuery = encodeURIComponent(partner?.name + ' ' + location.address.join());

    const isDetailsCollapsible =
      props.windowStore.isSmallerThan('lg') && ('sanoa_brand' === post.post_type || 'sanoa_service' === post.post_type);

    return (
      <div className={'ida-location-box' + (isDetailsCollapsible && !isDetailsOpen ? ' contact-hidden' : '')}>
        <div className="ida-location-box__content">
          {!isDetailsCollapsible && (
            <h3 id="location-box" className="-headline">
              {renderText(props.headerTitle)}
            </h3>
          )}
          <div className="d-flex">
            <div className="ida-location-box__logo">
              <Link className={trackingClassName(trackingParams, 'location-logo')} to={locationPath}>
                {getLogo()}
              </Link>
            </div>
            <div className="ida-location-box__header">
              <h3 className="-title">
                <Link className={trackingClassName(trackingParams, 'location-name')} to={locationPath}>
                  {renderText(partner?.name)}
                </Link>
              </h3>
              <div>{getAddress()}</div>
              {isDetailsCollapsible && (
                <div className={'-more-button' + (isDetailsOpen ? ' is-open' : '')} onClick={toggleContactDetails}>
                  Öffnungszeiten
                </div>
              )}
            </div>
          </div>
          <CollapseSimple isOpen={!isDetailsCollapsible || isDetailsOpen}>
            <div className="ida-location-box__contact">
              {phone && (
                <div className="d-flex">
                  <h4>Telefon:</h4>
                  <UniLink
                    className={'-button phone ' + trackingClassName(trackingParams, 'location-telefon')}
                    href={'tel:' + phone}
                    title={phone + ' anrufen'}
                  >
                    {phone}
                  </UniLink>
                </div>
              )}
              {location.email && (
                <div className="d-flex">
                  <h4>E-Mail:</h4>
                  <UniLink
                    className={'-button email ' + trackingClassName(trackingParams, 'location-email')}
                    href={'mailto:' + location.email + '?subject=Kontaktanfrage'}
                    title={'an ' + location.email + ' schreiben - öffnet das Mailprogramm'}
                  >
                    {location.email}
                  </UniLink>
                </div>
              )}
              {location.website && !isCurrentUrl(location.website) && (
                <div className="d-block">
                  <UniLink
                    href={'https://www.google.com/maps/search/?api=1&query=' + addressQuery}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Routenplaner
                  </UniLink>
                  <span> | </span>
                  <UniLink
                    className={'-button website ' + trackingClassName(trackingParams, 'location-homepage')}
                    href={location.website}
                    title={'Zur Webseite von ' + partner?.name}
                    target="_blank"
                  >
                    Zur Homepage
                  </UniLink>
                </div>
              )}
            </div>
            <div className="ida-location-box__info">
              {!location.tempClosed && hasOpeningHours && (
                <>
                  <h4>Öffnungszeiten:</h4>
                  <div className="row -hours">
                    <div className="col-12">
                      <DaysHours location={location} />
                    </div>
                  </div>
                </>
              )}
              {location.tempClosed && (
                <div className="font-weight-bold pb-2">Dieser Standort ist vorübergehend geschlossen.</div>
              )}
              <div className="row -info">
                <div className="col-12">{renderText(additionalHoursText)}</div>
              </div>
            </div>
          </CollapseSimple>
        </div>
        <div className="d-flex flex-wrap -cta">
          {actionbuttons &&
            actionbuttons.map((button, i) => {
              return (
                <UniLink
                  key={'link-' + i}
                  className={'-button ' + trackingClassName(trackingParams, 'location-action-button')}
                  href={button.link?.url}
                  target={button.link?.target}
                  title={button.link?.title}
                >
                  {button.link?.title}
                </UniLink>
              );
            })}
        </div>
      </div>
    );
  })
);

export default LocationBox;
