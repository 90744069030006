import React from 'react';
import { inject, observer } from 'mobx-react';
import { FreeMode, Navigation, Virtual } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { renderText } from '../../../helpers/api';
import UniLink from '../../elements/UniLink/UniLink';

/**
 * swiper slider on desktops with more than one slide
 * turns on mobile or with only one slide to teasers
 * @see https://swiperjs.com/swiper-api
 */

const ACFSliderLG = inject('windowStore')(
  observer((props) => {
    const windowStore = props.windowStore;
    const sliderItems = props.content.mink_fc_slider_lg_items;
    if (!sliderItems) {
      return null;
    }
    const isSlider = 2 < sliderItems.length && windowStore.isGreaterEqualThan('sm');
    const isTeaser = windowStore.isSmallerThan('sm');
    const itemWidth = windowStore.isGreaterEqualThan('xl') ? 400 : 360;

    const SliderItem = ({ item, showImages }) => {
      let linkUrl = false;
      if (item.mink_fc_slider_lg_item_link) {
        linkUrl = item.mink_fc_slider_lg_item_link.url;
      }
      return (
        <div className="-item-wrapper -shadow">
          {linkUrl && (
            <UniLink
              className="mink-slider-lg__item-link"
              href={linkUrl}
              target={item.mink_fc_slider_lg_item_link.target}
              title={item.mink_fc_slider_lg_item_title}
            />
          )}
          {showImages ? (
            <div className="-wrapper">
              <div className="aspect-content">
                <div className="-img">
                  {item.mink_fc_slider_lg_item_img ? (
                    <img
                      width={item.mink_fc_slider_lg_item_img.sizes['small-width']}
                      height={item.mink_fc_slider_lg_item_img.sizes['small-height']}
                      src={item.mink_fc_slider_lg_item_img.sizes.small}
                      alt={item.mink_fc_slider_lg_item_img.alt}
                      title={item.mink_fc_slider_lg_item_img.title}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className={showImages ? '-text-wrapper' : '-text-wrapper -no-img'}>
            {linkUrl ? (
              <div className="-head">
                <UniLink href={linkUrl} target={item.mink_fc_slider_lg_item_link.target}>
                  {renderText(item.mink_fc_slider_lg_item_title)}
                </UniLink>
              </div>
            ) : (
              <div className="-head-no-link">{renderText(item.mink_fc_slider_lg_item_title)}</div>
            )}
            <div className="-text">{renderText(item.mink_fc_slider_lg_item_text)}</div>
          </div>
          {linkUrl ? (
            <div className="-link-wrapper">
              <div className="-link">
                <UniLink href={linkUrl} target={item.mink_fc_slider_lg_item_link.target}>
                  {renderText(item.mink_fc_slider_lg_item_link.title)}
                </UniLink>
              </div>
            </div>
          ) : null}
        </div>
      );
    };

    if (isTeaser) {
      return (
        sliderItems &&
        sliderItems.map((item, i) => {
          return (
            <div className="mink-teaser-lg" key={i + '_' + item.text}>
              <SliderItem item={item} showImages={props.content.mink_fc_slider_lg_show_images} />
            </div>
          );
        })
      );
    } else if (isSlider) {
      return (
        <Swiper
          slidesOffsetBefore={5}
          slidesOffsetAfter={5}
          breakpoints={{
            576: { width: 360 },
            1360: { width: 400 },
          }}
          spaceBetween={16}
          watchOverflow={true}
          watchSlidesProgress={true}
          freeMode
          centerInsufficientSlides={false}
          navigation={sliderItems.length <= 2 ? false : true}
          modules={[Navigation, Virtual, FreeMode]}
          className="mink-slider-lg"
          virtual={{ enabled: true, addSlidesAfter: 5, addSlidesBefore: 5 }}
        >
          {sliderItems &&
            sliderItems.map((item, i) => {
              return (
                <SwiperSlide className="mink-slider-lg__item" virtualIndex={i} key={i + '_' + item.text}>
                  <SliderItem item={item} showImages={props.content.mink_fc_slider_lg_show_images} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      );
    } else {
      return (
        <div className="mink-slider-lg mink-slider-lg-inactive">
          {sliderItems &&
            sliderItems.map((item, i) => {
              return (
                <div className="mink-slider-lg__item" key={i + '_' + item.text} style={{ width: itemWidth - 6 }}>
                  <SliderItem item={item} showImages={props.content.mink_fc_slider_lg_show_images} />
                </div>
              );
            })}
        </div>
      );
    }
  })
);

export default ACFSliderLG;
