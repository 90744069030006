import React from 'react';
import CollapseRoot from '../../elements/Collapse/CollapseRoot';
import CollapseContent from '../../elements/Collapse/CollapseContent';
import CollapseTogglerMore from '../../elements/Collapse/CollapseTogglerMore';

const ShowMoreItems = ({ children, moreCount }) => {
  return (
    <>
      {children && moreCount > 0 && (
        <CollapseRoot>
          <CollapseContent>{children}</CollapseContent>
          <CollapseTogglerMore moreCount={moreCount} />
        </CollapseRoot>
      )}
    </>
  );
};

export default ShowMoreItems;
