import React from 'react';
import ACFAgenda from '../components/posts/ACFAgenda/ACFAgenda';
import ACFAnchor from '../components/posts/ACFAnchor/ACFAnchor';
import ACFAppointmentIframe from '../components/posts/ACFAppointmentIframe/ACFAppointmentIframe';
import ACFCtaButton from '../components/posts/ACFCtaButton/ACFCtaButton';
import ACFEmbedVimeo from '../components/posts/ACFEmbedVimeo/ACFEmbedVimeo';
import ACFEmbedYouTube from '../components/posts/ACFEmbedYouTube/ACFEmbedYouTube';
import ACFList from '../components/posts/ACFList/ACFList';
import ACFOnPageNav from '../components/posts/ACFOnPageNav/ACFOnPageNav';
import ACFPartnerLocations from '../components/posts/ACFPartnerLocations/ACFPartnerLocations';
import ACFPrescriptionUpload from '../components/posts/ACFPrescriptionUpload/ACFPrescriptionUpload';
import ACFReview from '../components/posts/ACFReview/ACFReview';
import ACFReviewLocationsList from '../components/posts/ACFReviewLocationsList/ACFReviewLocationsList';
import ACFSliderGooglePosts from '../components/posts/ACFSliderGooglePosts/ACFSliderGooglePosts';
import ACFSliderLG from '../components/posts/ACFSliderLG/ACFSliderLG';
import ACFSliderSM from '../components/posts/ACFSliderSM/ACFSliderSM';
import ACFImageSlider from '../components/posts/ACFImageSlider/ACFImageSlider';
import ACFTeaser from '../components/posts/ACFTeaser/ACFTeaser';
import ACFTextEditor from '../components/posts/ACFTextEditor/ACFTextEditor';
import ACFTopContent from '../components/posts/ACFTopContent/ACFTopContent';
import ACFLocatorMap from '../components/posts/ACFLocatorMap/ACFLocatorMap';
import stores from '../stores';

const renderPostFields = (post) => {
  const member = post.partner?.member;
  let formCounter = 0;

  const components = post.content.map((field, i) => {
    switch (field.acf_fc_layout) {
      case 'mink_fc_agenda':
        return <ACFAgenda key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_anchor':
        return <ACFAnchor key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_appointment':
        // if location is non sanoa member return null
        if (true !== member) {
          return null;
        }
        return <ACFAppointmentIframe key={`${post.ID}_${i}`} content={field} post={post} />;
      case 'mink_fc_cta':
        // if location is non sanoa member return null
        if (true !== member && 'url' !== field.mink_fc_cta_type) {
          return null;
        }
        return (
          <ACFCtaButton
            key={`${post.ID}_${i}`}
            content={field}
            partner={post.partner}
            location={post?.location}
            post_type={post.post_type}
            slug={post.slug}
          />
        );
      case 'mink_fc_embed_video':
        if ('vm' === field.mink_fc_embed_video_plattform) {
          return <ACFEmbedVimeo key={`${post.ID}_${i}`} content={field} />;
        } else if ('yt' === field.mink_fc_embed_video_plattform) {
          return <ACFEmbedYouTube key={`${post.ID}_${i}`} content={field} />;
        }
        return null;
      case 'mink_fc_list':
        return <ACFList key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_onpagenav':
        return <ACFOnPageNav key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_partner_locations':
        return <ACFPartnerLocations key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_prescription_form':
        formCounter++;
        // if location is non sanoa member return null
        if (true !== member) {
          return null;
        }
        return <ACFPrescriptionUpload key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_review_form':
        formCounter++;
        // if location is non sanoa member return null
        if (true !== member) {
          return null;
        }
        return <ACFReview key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_review_list':
        // if location is non sanoa member return null
        if (true !== member) {
          return null;
        }
        return (
          <ACFReviewLocationsList
            key={`${post.ID}_${i}`}
            content={field}
            partner={post.partner.slug}
            post_type={post.post_type}
            slug={post.slug}
          />
        );
      case 'mink_fc_google_posts':
        // if location is non sanoa member return null
        if (true !== member) {
          return null;
        }
        return <ACFSliderGooglePosts key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_slider_lg':
        return <ACFSliderLG key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_slider_sm':
        return <ACFSliderSM key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_image_slider':
        return <ACFImageSlider key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_teaser':
        return <ACFTeaser key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_text':
        return <ACFTextEditor key={`${post.ID}_${i}`} content={field} />;
      case 'mink_fc_intro':
        return (
          <ACFTopContent
            key={`${post.ID}_${i}`}
            content={field}
            logo={post?.logo}
            location={post?.location}
            post_type={post.post_type}
          />
        );
      case 'mink_fc_locator_map':
        return <ACFLocatorMap _key={`${post.ID}_${i}`} key={`${post.ID}_${i}`} {...field} />;
      default:
        return null;
    }
  });
  stores.formStore.setFormsCount(formCounter);
  return components;
};

/**
 * replace DE umlatus with regular string
 * @param {string} str
 * @returns string
 */

const replaceUmlauts = (str) => {
  return str
    .replaceAll('ß', 'ss')
    .replaceAll('ä', 'ae')
    .replaceAll('ö', 'oe')
    .replaceAll('ü', 'ue')
    .replaceAll('Ä', 'Ae')
    .replaceAll('Ö', 'Oe')
    .replaceAll('Ü', 'Ue');
};

/**
 * sanitizes id attr for url anchor hash in wordpress fashion
 * @param {string} str
 * @returns string|null
 */
const sanitizeId = (str) => {
  if (!str || '' === str) {
    return null;
  }
  let id = replaceUmlauts(str.replaceAll(' ', '-').toLowerCase());
  return id.replace(/[^a-z0-9_-]/g, '');
};

/**
 * removes html tags from string
 * @param {string} html
 * @returns {string}
 */
const stripTags = (html) => {
  if (!DOMParser) {
    return html;
  }

  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export { renderPostFields, replaceUmlauts, sanitizeId, stripTags };
