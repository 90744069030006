import React, { useEffect } from 'react';
import { matchPath, useLocation, useNavigate, useNavigationType, Outlet } from 'react-router-dom';
import { ROUTES as routes } from './routes/routes';
import ConsentModal from './components/elements/ConsentModal/ConsentModal';
import GTM from './components/elements/GTM/GTM';
import { observer, inject } from 'mobx-react';
import { RouterContext } from './stores/RouterContext';
import { getConfig } from './helpers/api';
import NoMatch from './containers/NoMatch/NoMatch';

const App = inject(
  'navigationStore',
  'routerStore'
)(
  observer((props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const navigationType = useNavigationType();
    let slug = false;
    let postType = false;
    const subdomain = getConfig('subdomain');
    let locationSlug = '';
    let partnerPage = false;
    let locationPath = '';
    let isPostTypeArchive = false;
    const is404 = '1' === getConfig('is_404');

    let match = null;
    for (let i = 0; i < routes.length; i++) {
      match = matchPath(routes[i], location.pathname);
      if (match) {
        break;
      }
    }
    if (match && match.params) {
      if (match.params.location) {
        locationSlug = match.params.location;
      }
      if (match.pattern?.apiParams?.postType) {
        partnerPage = match.pattern.apiParams.partnerPage ? true : false;
        postType = match.pattern?.apiParams?.postType;
        isPostTypeArchive = 'post_type_archive' === match.pattern?.apiParams?.type;
        if ('sanoa_partner_pages' === postType) {
          slug = match.params?.id ? match.params?.id : subdomain;
          partnerPage = true;
        } else if ('sanoa_location' === postType) {
          slug = match.params.id;
          locationSlug = slug;
          locationPath = '/standort/' + locationSlug;
        } else if (-1 !== ['sanoa_service', 'sanoa_brand'].indexOf(postType)) {
          slug = match.params.id;
          locationPath = '/standort/' + locationSlug;
        } else if (postType.startsWith('sanoa_portal')) {
          if (isPostTypeArchive && match.pattern?.apiParams?.id) {
            slug = match.pattern?.apiParams?.id;
          } else {
            slug = match.params?.id ? match.params?.id : getConfig('portal_home');
          }
        }
      } else {
        const newSlug = match.params.id ? match.params.id : '';
        slug = newSlug;
      }
    }
    const routerContextValue = {
      slug: slug,
      postType: postType,
      subdomain: subdomain,
      partnerSlug: subdomain,
      location: location,
      locationSlug: locationSlug,
      partnerPage: partnerPage,
      locationPath: locationPath,
      navigationType: navigationType,
      isPostTypeArchive: isPostTypeArchive,
      is404: is404,
    };

    useEffect(() => {
      props.routerStore.syncRouterWithStore(navigate, location, navigationType, match);
    }, [location, match, navigate, navigationType, props.routerStore]);

    if (is404) {
      return <NoMatch />;
    }

    return (
      <RouterContext.Provider value={routerContextValue}>
        <div className="wrap">
          <main className="ida-main">
            <Outlet />
          </main>
        </div>
        <ConsentModal />
        <GTM />
      </RouterContext.Provider>
    );
  })
);

export default App;
