import React, { useState } from 'react';
import { CollapseContext } from './CollapseContext';

const CollapseRoot = ({ className, children, defaultOpen }) => {
  const [open, setOpen] = useState(defaultOpen === true ? true : false);
  const toggleOpen = () => {
    setOpen((oldStatus) => !oldStatus);
  };
  const classNames = (className ? className + ' ' : '') + 'collapse-root ' + (open ? 'open' : 'closed');
  return (
    <CollapseContext.Provider value={{ open, setOpen, toggleOpen }}>
      <div className={classNames}>{children}</div>
    </CollapseContext.Provider>
  );
};

export default CollapseRoot;
