import React from 'react';
import { Link } from 'react-router-dom';
import { renderText, getCollapseThreshold } from '../../../helpers/api';
import ShowMoreItems from '../../elements/ShowMoreItems/ShowMoreItems';

/**
 * Displays a box with all locations of the current partner
 * @see https://sanoa.atlassian.net/wiki/spaces/SD/pages/320634881/ACF+Flexible+Content+blocks#List-all-locations-of-current-partner
 */

const ACFPartnerLocations = (props) => {
  const content = props.content;

  const getLocations = () => {
    // filter the current locations
    const locations = content.partner_locations.children
      ? content.partner_locations.children.filter((loc) => loc.ID !== content.partner_locations.current_ID)
      : [];

    const t = getCollapseThreshold('locations');
    const elements = locations.map((loc, i) => {
      return (
        <li key={i}>
          <Link to={loc.url}>{renderText(loc.title)}</Link>
        </li>
      );
    });

    const elementsCollapsed = t + 1 < elements.length ? elements.splice(t) : [];
    if (locations.length) {
      return (
        <div className="mink-partner-locations">
          <h3 className="mink-partner-locations__header">{content.partner_locations.title}</h3>
          <div className="mink-partner-locations__content">
            <ul>{elements}</ul>
            <ShowMoreItems moreCount={elementsCollapsed.length}>
              <ul>{elementsCollapsed}</ul>
            </ShowMoreItems>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return getLocations();
};

export default ACFPartnerLocations;
