import React, { useContext } from 'react';
import { CollapseContext } from './CollapseContext';

const CollapseTrigger = ({ className, children }) => {
  const collapseContext = useContext(CollapseContext);
  const isOpen = collapseContext.open;
  const classNames = (className ? className + ' ' : '') + 'collapse-trigger ' + (isOpen ? 'open' : 'closed');

  const onClickTrigger = () => {
    collapseContext.setOpen(!collapseContext.open);
  };
  return (
    <div className={classNames} onClick={onClickTrigger}>
      {children}
    </div>
  );
};

export default CollapseTrigger;
