import React, { useContext, useRef } from 'react';
import SubMenuItem from '../../nav/SubmenuItem/SubMenuItem';
import { Link } from 'react-router-dom';
import CollapseSimple from '../../elements/Collapse/CollapseSimple';
import useEventListener from '@use-it/event-listener';
import { observer, inject } from 'mobx-react';
import { RouterContext } from '../../../stores/RouterContext';
import HeaderNavSocial from './HeaderNavSocial';

const HeaderNav = inject('navigationStore')(
  observer((props) => {
    const navigation = props.post.navigation;
    const routerContext = useContext(RouterContext);
    const element = useRef(null);
    const locationPath = routerContext.locationPath;
    const locationSlug = routerContext.locationSlug;

    const handleDocumentClick = (e) => {
      // close menu on outside clicks
      const container = element.current;
      // check if menu button was clicked to prevent open state problems
      if (
        e.target !== container &&
        e.target !== props.navigationStore.menuButton &&
        (!props.navigationStore.menuButton || !props.navigationStore.menuButton.contains(e.target)) &&
        !container.contains(e.target)
      ) {
        props.navigationStore.closeNav();
      }
    };

    useEventListener('click', handleDocumentClick, document, { capture: true });

    let locationsMenu = navigation?.locations?.children?.length ? navigation.locations : null;

    const serviceMenu = navigation?.services?.children?.length ? navigation.services : null;
    const brandMenu = navigation?.brands?.children?.length ? navigation.brands : null;
    // indicates if location param is set, so it is not a location
    const locationLink = routerContext.location.pathname.includes(locationPath) ? true : false;
    if (serviceMenu?.children?.length) {
      serviceMenu.children.forEach((child) => {
        if (child?.children?.length) {
          child.children.forEach((item) => {
            item.url = item.url.includes(locationPath) ? item.url : locationPath + item.url;
          });
        }
      });
    }
    if (brandMenu?.children?.length) {
      brandMenu.children.forEach((child) => {
        if (child?.children?.length) {
          child.children.forEach((item) => {
            item.url = item.url.includes(locationPath) ? item.url : locationPath + item.url;
          });
        }
      });
    }

    const socialData = navigation.social_networks;
    const recruitingLink = socialData['recruiting_link'];
    if (recruitingLink) {
      recruitingLink.target = '_blank';
      recruitingLink.key = 'recruiting_link';
      if (!recruitingLink.title) {
        recruitingLink.title = 'Stellenangebote';
      }
    }

    return (
      <CollapseSimple isOpen={props.navigationStore.navIsActive}>
        <div className="ida-header__menu" ref={element}>
          <div className="container">
            <nav>
              <HeaderNavSocial post={props.post} socialData={socialData} />
              <ul className={'main-menu list-unstyled'}>
                <li className={'nav_item'}>
                  <Link
                    onClick={(e) => {
                      //props.navigationStore.closeNav();
                    }}
                    className="main-menu-link"
                    to={'/'}
                  >
                    Homepage
                  </Link>
                </li>
                {locationSlug && locationLink && (
                  <li className={'nav_item'}>
                    <Link
                      onClick={(e) => {
                        //props.navigationStore.closeNav();
                      }}
                      className="main-menu-link"
                      to={locationPath + '/'}
                    >
                      Standortinformationen
                    </Link>
                  </li>
                )}
                {locationsMenu?.children?.length && (
                  <SubMenuItem key="locations" item={locationsMenu} depthLevel={0} rootElement={element} />
                )}
                {serviceMenu?.children?.length && (
                  <SubMenuItem key="services" item={serviceMenu} depthLevel={0} rootElement={element} />
                )}
                {brandMenu?.children?.length && (
                  <SubMenuItem key="brands" item={brandMenu} depthLevel={0} rootElement={element} />
                )}
                {recruitingLink && (
                  <SubMenuItem key={'recruiting_link'} item={recruitingLink} depthLevel={0} rootElement={element} />
                )}
                {/*props.navigationStore.footerNavigationItems.map((item) => {
                  return (
                    <SubMenuItem
                      key={'mi' + item.menu_item_id}
                      item={item}
                      depthLevel={0}
                      rootElement={element}
                    />
                  );
                })*/}
              </ul>
            </nav>
          </div>
        </div>
      </CollapseSimple>
    );
  })
);

export default HeaderNav;
