import React from 'react';
import { inject, observer } from 'mobx-react';
import { Navigation, Virtual, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { renderText } from '../../../helpers/api';
/**
 * editorial swiper slider displays custom amount of slides
 * @see https://swiperjs.com/swiper-api
 */

const ACFImageSlider = inject('windowStore')(
  observer((props) => {
    const aspectRatio = props.content.mink_fc_image_slider_ratio;
    const aspectRatioClass = aspectRatio === '4:3' ? 'aspect-ratio-4x3' : 'aspect-ratio-1x1';

    const sliderItems = props.content.mink_fc_image_slider_items;
    const isSlider = 1 < sliderItems?.length ? true : false;

    const SliderItem = ({ item, showImages }) => {
      const img = item.mink_fc_image_slider_item_img;
      const html = item.mink_fc_image_slider_item_text;

      return (
        <div className="-item-wrapper">
          {html && <div className="-text">{renderText(html)}</div>}
          <div className={'-wrapper ' + aspectRatioClass}>
            <div className="aspect-content">
              <div className="-img">
                {img ? (
                  <img
                    width={img.sizes['large-width']}
                    height={img.sizes['large-height']}
                    src={img.sizes.large}
                    alt={img.alt}
                    title={img.title}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    };

    if (isSlider) {
      return (
        <Swiper
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          centerInsufficientSlides={false}
          navigation={sliderItems.length > 1 ? true : false}
          pagination={{
            enabled: sliderItems.length > 1 && sliderItems.length <= 20 ? true : false,
            clickable: true,
          }}
          modules={[Navigation, Virtual, Pagination]}
          className="mink-image-slider"
          virtual={{ enabled: true, addSlidesAfter: 5, addSlidesBefore: 5 }}
        >
          {sliderItems &&
            sliderItems.map((item, i) => {
              return item.mink_fc_image_slider_item_img ? (
                <SwiperSlide
                  virtualIndex={i}
                  key={i + '_' + item.mink_fc_image_slider_item_img.ID}
                  className="mink-image-slider__item"
                >
                  <SliderItem item={item} />
                </SwiperSlide>
              ) : null;
            })}
        </Swiper>
      );
    } else {
      return (
        <div className="mink-image-slider mink-image-slider-inactive">
          {sliderItems &&
            sliderItems.map((item, i) => {
              return item.mink_fc_image_slider_item_img ? (
                <div key={i + '_' + item.mink_fc_image_slider_item_img.ID} className="mink-image-slider__item">
                  <SliderItem item={item} />
                </div>
              ) : null;
            })}
        </div>
      );
    }
  })
);

export default ACFImageSlider;
