import { WINDOW_EXISTS } from '../../helpers/viewport';
import { action, observable, makeObservable } from 'mobx';

export default class WindowStore {
  delay = 250;
  /**
   * holder for timeout id
   */
  timeout = false;
  pageSize = 'xs';
  windowHeight = 0;
  scrollHeight = 0;
  scrollWidth = 0;
  // windowRatio <= 1 landscape; windowRatio > 1 portrait
  windowRatio = 0.75;
  containerRef = null;
  resizeObserver = null;

  /**
   * array with window sizes
   * @see https://getbootstrap.com/docs/4.0/layout/grid/
   */
  sizes = [
    { name: 'xs', width: 0 },
    { name: 'sm', width: 576 },
    { name: 'md', width: 768 },
    { name: 'lg', width: 992 },
    { name: 'xl', width: 1360 },
    { name: 'xxl', width: 1860 },
  ];

  imageSizes = [
    { name: 'xs', suffix: 'medium' },
    { name: 'md', suffix: 'large' },
    { name: 'lg', suffix: '' },
  ];

  currentImageSizeIndex = 0;

  sizesOrder = this.sizes.map((size) => size.name);
  imageSizesOrder = this.imageSizes.map((size) => size.name);

  constructor() {
    makeObservable(this, {
      pageSize: observable,
      windowHeight: observable,
      scrollHeight: observable,
      scrollWidth: observable,
      windowRatio: observable,
      setPageSize: action,
      setWindowHeight: action,
      setWindowRatio: action,
      setScrollSize: action,
    });

    this.handleResize = this.handleResize.bind(this);
    if (WINDOW_EXISTS) {
      window.addEventListener('resize', this.handleResize);
      this.setPageSize();
      this.setWindowHeight();
      this.setWindowRatio();
    }
  }

  handleResize() {
    // clear the timeout
    clearTimeout(this.timeout);
    // start timing for event "completion"
    this.timeout = setTimeout(() => {
      this.setPageSize();
      this.setWindowHeight();
      this.setWindowRatio();
    }, this.delay);
  }

  get innerWidth() {
    if (WINDOW_EXISTS) {
      return window.innerWidth;
    } else {
      return 1;
    }
  }

  get innerHeight() {
    if (WINDOW_EXISTS) {
      return window.innerHeight;
    } else {
      return 1;
    }
  }

  get scrollPosition() {
    if (WINDOW_EXISTS) {
      return window.pageYOffset;
    } else {
      return 0;
    }
  }

  setPageSize = () => {
    const w = window.innerWidth;
    let currentSize = this.sizes[0].name;
    for (let i = 1; i < this.sizes.length; i++) {
      if (this.sizes[i].width > w) {
        break;
      }
      currentSize = this.sizes[i].name;
    }
    this.pageSize = currentSize;
  };

  setWindowHeight = () => {
    this.windowHeight = this.innerHeight;
  };

  /**
   *
   * @param {number} width
   * @param {number} height
   */
  setScrollSize = (width, height) => {
    this.scrollHeight = height;
    this.scrollWidth = width;
  };

  startResizeObserver = (elementRef) => {
    if (elementRef.current && 'undefined' !== typeof ResizeObserver) {
      this.containerRef = elementRef;
      this.resizeObserver = new ResizeObserver(
        this.throttle((entries) => {
          for (const entry of entries) {
            this.setScrollSize(entry.contentRect.width, entry.contentRect.height);
          }
        }),
        300
      );
      this.resizeObserver.observe(this.containerRef.current);
    }
  };

  stopResizeObserver = () => {
    if (this.resizeObserver instanceof ResizeObserver) {
      this.resizeObserver.disconnect();
    }
  };

  throttle = (f, delay) => {
    let timer = 0;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => f.apply(this, args), delay);
    };
  };

  setWindowRatio = () => {
    this.windowRatio = parseFloat((this.innerHeight / this.innerWidth).toPrecision(2));
  };

  isGreaterThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) > this.sizesOrder.indexOf(size);
  }

  isSmallerThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) < this.sizesOrder.indexOf(size);
  }

  isGreaterEqualThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) >= this.sizesOrder.indexOf(size);
  }

  isSmallerEqualThan(size) {
    return this.sizesOrder.indexOf(this.pageSize) <= this.sizesOrder.indexOf(size);
  }

  /**
   * get the header images size suffix for viewport width
   * use as ['thumbnail' +  suffix]
   */
  getHeaderImageSizeSuffix() {
    if (this.isGreaterEqualThan('xl')) return 'large';
    if (this.isGreaterEqualThan('lg')) return 'small'; // large layout has the smallest image
    return 'medium_large';
  }
}
