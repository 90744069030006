import React, { useEffect, useRef } from 'react';
import { observer, inject } from 'mobx-react';

const HeaderMenuButton = inject('navigationStore')(
  observer((props) => {
    const isOpen = props.navigationStore.navIsActive;
    const onMenuClick = () => {
      props.navigationStore.toggleNav();
    };
    const buttonElement = useRef(null);

    useEffect(() => {
      props.navigationStore.setMenuButton(buttonElement.current);
    }, [buttonElement, props.navigationStore]);

    return (
      <div className={'ida-header__menu-button' + (isOpen ? ' is-open' : '')} onClick={onMenuClick} ref={buttonElement}>
        <span />
        <span />
        <span />
        <span />
      </div>
    );
  })
);

export default HeaderMenuButton;
