import React, { useContext } from 'react';
import { CollapseContext } from './CollapseContext';

const CollapseContent = ({ className, children }) => {
  const collapseContext = useContext(CollapseContext);
  const isOpen = collapseContext.open;
  const classNames = (className ? className + ' ' : '') + 'collapse' + (isOpen ? ' show' : '');
  return <div className={classNames}>{children}</div>;
};

export default CollapseContent;
